<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col class="pr-12">
          <h1>Order GPC{{ order.id }} - {{ order.name }}</h1>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed :to="{ name: 'module-graham-orders' }"
              >Return to Orders</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9">
          <v-row no-gutters>
            <v-col cols="12">
              <v-card outlined class="mb-6">
                <v-toolbar flat dark dense :color="appColor">
                  <v-toolbar-title>Pests</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>

                <v-simple-table>
                  <thead>
                    <tr>
                      <th></th>
                      <th class="text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="py-2">
                        <div class="text-subtitle-1 font-weight-bold">
                          {{ order.pest_name }}
                        </div>
                      </td>

                      <td class="text-right">£{{ order.total }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-col>

            <v-row>
              <v-col cols="6">
                <v-card outlined class="mb-6">
                  <v-toolbar flat dark dense :color="appColor">
                    <v-toolbar-title>Payment</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-simple-table class="mb-5 table" divider="true">
                    <tbody>
                      <tr>
                        <td>Payment Intent</td>
                        <td>{{ order.payment_intent }}</td>
                      </tr>
                      <tr>
                        <th>Status</th>
                        <td>
                          <div>
                            <v-chip
                              label
                              v-if="order.status === 'processing'"
                              color="blue"
                              text-color="white"
                              >Processing</v-chip
                            >
                            <v-chip
                              label
                              v-else-if="order.status === 'pending-payment'"
                              color="warning"
                              >Pending Payment</v-chip
                            >
                            <v-chip
                              label
                              v-else-if="order.status === 'cancelled'"
                              >Cancelled</v-chip
                            >
                            <v-chip
                              label
                              v-else-if="order.status === 'complete'"
                              color="success"
                              >Complete</v-chip
                            >
                            <v-chip
                              label
                              v-else-if="order.status === 'generated'"
                              color="success"
                              >Generated</v-chip
                            >
                            <v-chip
                              label
                              v-else-if="order.status === 'sent-to-customer'"
                              color="success"
                              >Sent To Customer</v-chip
                            >
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td>{{ formatDateTime(order.created_at, true) }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>

                <v-card class="mb-6">
                  <v-toolbar flat dark dense :color="appColor">
                    <v-toolbar-title>Audit</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-divider></v-divider>

                  <v-data-table
                    :headers="tableHeaders"
                    :items="order.audits"
                    no-data-text="No Audit"
                    :items-per-page="-1"
                  >
                    <template v-slot:item.audit_date="{ item }">
                      {{ formatDateTime(item.audit_date, true) }}
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>

              <v-col cols="6">
                <v-card outlined class="mb-6">
                  <v-toolbar flat dark dense :color="appColor">
                    <v-toolbar-title>Other Details</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-simple-table class="mb-5 table" divider="true">
                    <tbody>
                      <tr>
                        <th>Order Type</th>
                        <td>{{ order.order_type }}</td>
                      </tr>
                      <tr>
                        <th>Location of pest on the property</th>
                        <td>{{ order.location_on_property }}</td>
                      </tr>
                      <tr>
                        <th>Have you used our services before?</th>
                        <td>{{ order.used_our_services ? "Yes" : "No" }}</td>
                      </tr>

                      <tr v-for="(input, name) in order.content" :key="name">
                        <th>{{ name }}</th>
                        <td
                          style="padding-top: 8px; padding-bottom: 8px"
                          v-html="input"
                        ></td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-select
            label="Order Status"
            v-model="status"
            :items="statuses"
            :loading="statusLoading"
            hint="Update the Order Status here"
            outlined
            persistent-hint
            @change="statusChanged()"
          ></v-select>

          <v-card outlined class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Address</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <div>{{ order.name }}</div>
              <div>{{ order.address }}</div>

              <div>{{ order.town }}</div>
              <div>{{ order.city }}</div>

              <div>{{ order.postcode }}</div>

              <div class="mt-2">
                <strong>Email Address:</strong>&nbsp;
                <a :href="`mailto:${order.email}`">{{ order.email }}</a>
              </div>

              <div class="mt-2">
                <strong>Phone:</strong>&nbsp;
                <a :href="`tel:${order.phone}`">{{ order.phone }}</a>
              </div>
            </v-card-text>
          </v-card>

          <v-card outlined class="mb-6" v-if="order.pest_picture">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Pest Picture</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <div>
                <v-img
                  :src="order.asset_urls.pest_picture"
                  class="white--text align-end"
                  gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                >
                </v-img>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Orders",
          disabled: false,
          exact: true,
          to: {
            name: "module-graham-orders",
          },
        },
        {
          text: "Individual Order",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Date/Time", value: "audit_date" },
        { text: "User", value: "user.full_name" },
        { text: "Activity", value: "audit_text" },
      ],
      statuses: [
        { text: "Processing", value: "processing" },
        { text: "Pending Payment", value: "pending-payment" },
        { text: "Cancelled", value: "cancelled" },
        { text: "Complete", value: "complete" },
        { text: "Generated", value: "generated" },
        { text: "Sent To Customer", value: "sent-to-customer" },
      ],
      status: null,
      statusLoading: false,
    };
  },

  computed: {
    order() {
      return this.$store.state.graham.orders["order"];
    },
  },

  mounted() {
    delete this.order.content["Checkbox"];
    this.status = this.order.status;
  },

  methods: {
    statusChanged: function () {
      this.statusLoading = true;

      this.$store
        .dispatch("graham/orders/saveOrder", {
          appId: this.$route.params.id,
          orderId: this.order.id,
          fields: {
            status: this.status,
          },
        })
        .then(() => {
          this.statusLoading = false;
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Order Status Updated Successfully!",
            color: "success",
          });
        })
        .catch(() => (this.statusLoading = false));
    },
  },
};
</script>
